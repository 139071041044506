<template>
  <div class="container">
    <Header active="report" />
    <div class="main">
      <el-carousel class="banner" @change="bannerChange" interval="6000">
        <el-carousel-item>
          <div class="item">
            <transition
              enter-active-class="animate__animated animate__fadeIn cus-animated"
              leave-active-class="animate__animated animate__fadeOut"
            >
              <div v-if="b1show" key="ban01" class="text">
                <div class="title">
                  行业专家更权威&nbsp;&nbsp;业务定制更专业
                </div>
                <div class="desc">
                  按业务与行业精选相关行业内权威专家团队倾情力作，以数据作为基础多角度的分析案例最终得出专业的结果和结论
                </div>
              </div>
            </transition>
            <img
              class="img"
              :src="require('../../../assets/images/website/report.jpg')"
            />
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="data">
        <el-tabs type="border-card">
          <el-tab-pane label="行业市场分析报告">
            <div class="sift">
              <el-row class="region">
                <el-col :span="2" class="tle">选择行业：</el-col>
                <el-col :span="22" class="items">
                  <span class="item"
                    ><a href="javascript:void" @click="classifySearch('', '')"
                      >全部行业</a
                    ></span
                  >
                  <span
                    class="item"
                    v-for="classify in classifys"
                    :key="classify.code"
                    ><a
                      href="javascript:void"
                      @click="classifySearch(classify.code, classify.name)"
                      >{{ classify.name }}</a
                    ></span
                  >
                </el-col>
              </el-row>
              <el-row class="years">
                <el-col :span="2" class="tle">选择年份：</el-col>
                <el-col :span="22" class="items">
                  <span class="item"
                    ><a href="javascript:void" @click="yearsSearch('')"
                      >全部年限</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="yearsSearch(2023)"
                      >2023年</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="yearsSearch(2022)"
                      >2022年</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="yearsSearch(2021)"
                      >2021年</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="yearsSearch(2020)"
                      >2020年</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="yearsSearch(2019)"
                      >2019年</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="yearsSearch(2018)"
                      >2018年</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="yearsSearch(2017)"
                      >2017年</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="yearsSearch(2016)"
                      >2016年</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="yearsSearch(2015)"
                      >2015年</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="yearsSearch(2014)"
                      >2014年</a
                    ></span
                  >
                  <span class="item"
                    ><a href="javascript:void" @click="yearsSearch(2013)"
                      >2013年</a
                    ></span
                  >
                </el-col>
              </el-row>
            </div>
            <div class="content">
              <el-row :gutter="20">
                <el-col :span="12" v-for="market in markets" :key="market.code">
                  <div class="item">
                    <el-row>
                      <el-col :span="12"
                        ><div class="left">
                          <div class="tle">
                            <span class="fc-cyan">九维</span>&nbsp;<span
                              class="fc-orange"
                              >白皮书</span
                            >
                          </div>
                          <div class="name">{{ market.name }}</div>
                          <div class="name">（定制版）</div>
                        </div></el-col
                      >
                      <el-col :span="12">
                        <div class="right">
                          <div class="name text-short">
                            {{ market.name }}
                          </div>
                          <el-row class="btd old-price">
                            <el-col :span="8">作者：</el-col>
                            <el-col :span="16">九维智库</el-col>
                          </el-row>
                          <el-row class="btd years">
                            <el-col :span="8">年限：</el-col>
                            <el-col :span="16" class="years"
                              >{{ market.years }}年</el-col
                            >
                          </el-row>
                          <el-row class="btd old-price">
                            <el-col :span="8">原价：</el-col>
                            <el-col :span="16"
                              ><s>{{ market.priceOld }}/份</s></el-col
                            >
                          </el-row>
                          <el-row class="btd new-price fc-red">
                            <el-col :span="8">现价：</el-col>
                            <el-col :span="16">{{ market.priceNew }}/份</el-col>
                          </el-row>

                          <el-row class="btn btd">
                            <el-col :span="24"
                              ><el-button
                                type="primary"
                                icon="el-icon-s-promotion"
                                size="mini"
                                @click="serviceApply(market.code)"
                                >提交意向申请</el-button
                              ></el-col
                            >
                          </el-row>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
            </div>
            <Pagination :callback="marketCallback" ref="marketPage" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <el-dialog title="数据下载申请" v-model="applyState">
      <el-form :model="ruleForm" label-width="80px" class="form">
        <el-form-item label="姓名">
          <el-input
            v-model="ruleForm.name"
            placeholder="请填写您的姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="电话">
          <el-input
            v-model="ruleForm.phone"
            placeholder="请填写您的联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input
            v-model="ruleForm.description"
            :rows="2"
            type="textarea"
            placeholder="请简要描述您的业务需求"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="applyState = false">取消</el-button>
          <el-button type="primary" @click="serviceApplySave"> 确认 </el-button>
        </span>
      </template>
    </el-dialog>
    <Footer />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="scss" scoped>
@import './Index.scss';
</style>
