import 'animate.css'
import '../../../assets/icon/font-icon.js'
import Header from '../common/Header.vue'
import Footer from '../common/Footer.vue'
import Pagination from '../../../components/Pagination.vue'
import { ElCarousel, ElCarouselItem, ElTabs, ElTabPane, ElDialog, ElSelect, ElOption, ElForm, ElFormItem, ElInput } from 'element-plus'
export default {
    components: {
        Header: Header,
        Footer: Footer,
        Pagination: Pagination,
        [ElCarousel.name]: ElCarousel,
        [ElCarouselItem.name]: ElCarouselItem,
        [ElTabs.name]: ElTabs,
        [ElTabPane.name]: ElTabPane,
        [ElDialog.name]: ElDialog,
        [ElSelect.name]: ElSelect,
        [ElOption.name]: ElOption,
        [ElForm.name]: ElForm,
        [ElFormItem.name]: ElFormItem,
        [ElInput.name]: ElInput
    },
    data () {
        return {
            b1show: false,
            applyState: false,
            marketCode: '',
            classifyCode: '',
            classifyName: '',
            years: '',
            markets: [],
            classifys: [],
            ruleForm: {
                name: '',
                phone: '',
                description: ''
            }
        }
    },
    mounted () {
        this.color_m = window.const.global.HRP_COLOR_M
        this.color_s1 = window.const.global.HRP_COLOR_S1
        this.retrieveClassify()
        this.search()
        this.b1show = true
    },
    methods: {
        bannerChange (val) {
            if (val === 0) {
                this.b1show = true
                this.b2show = false
            } else {
                this.b1show = false
                this.b2show = true
            }
        },
        async retrieveClassify () {
            var pd = { upperCode: '0' }
            const { data: res } = await this.$http.post(this.BMS_URL + '/report/classify/retrieveClassifyList', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.classifys = res.data
            }
        },
        marketCallback (data) {
            this.markets = data
        },
        classifySearch (classifyCode, classifyName) {
            this.classifyCode = classifyCode
            this.classifyName = classifyName
            this.search()
        },
        yearsSearch (years) {
            this.years = years
            this.search()
        },
        serviceApply (code) {
            this.applyState = true
            this.marketCode = code
        },
        async serviceApplySave () {
            if (this.ruleForm.name === '') {
                this.$alert('请填写姓名', '提示信息')
            } else if (this.ruleForm.phone === '') {
                this.$alert('请填写手机号码', '提示信息')
            } else {
                var content = { name: this.ruleForm.name, phone: this.ruleForm.phone, description: this.ruleForm.description }
                var param = { type: 'MKT', code: this.marketCode }
                var pd = { app: 'PCW_TTP', label: 'TTP_REPORT_SEND', param: JSON.stringify(param), content: JSON.stringify(content) }
                const { data: res } = await this.$http.post(this.BMS_URL + '/call/message/createMessage', this.$qs.stringify(pd))
                if (res.status === '200') {
                    this.$alert('提交成功请耐心等待', '提示信息').then(() => {
                        this.applyState = false
                    })
                } else {
                    this.$alert('提交失败，请联系在线客服', '提示信息').then(() => {
                        this.applyState = false
                    })
                }
            }
        },
        async search () {
            var pd = { classifyCode: this.classifyCode, years: this.years }
            this.$refs.marketPage.cusSearchPage(this.BMS_URL + '/report/marketAPC/retrieveMarketPage', pd, { size: 16 })
        }
    }
}
